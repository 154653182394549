@include responsive(0,2200px) {
  .hero {
    min-height: 65vh !important;
    &-about {
      min-height: 65vh !important; } } }

@include responsive(1700px,0) {
  .hero {
    &-about {
      padding-bottom: 380px; } } }
@include responsive(1500px,0) {
  .hero {
    &-about {
      padding-bottom: 380px;
 } } }      // update slider

@include responsive(1370px,770px) {
  .hero {
    max-height: 100vh;
    &_slider {
      max-height: initial; } } }
@include responsive(0,1280px) {
  .container {
    max-width: 1224px; } }
@include responsive(0,426px) {
  .footer_copy {
    .text-right {
      display: none; } } }

@include responsive(0,1200px) {
  .hs2 {
    &_title {
      .col-md-6 {
        padding-left: 52px; } } } }
@include responsive(1200px,0) {
  html, body {
    font-size: 16px;
    line-height: 24px; }

  .header {
    z-index: 4;
    top: 0;
    .container {
      max-width: 100%; }
    &_logo {
      max-width: 100px; }
    &_left-menu,&_right-menu {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      text-align: left;
      padding: 16px;
      &> li:not(.li) {
        display: block;
        margin: 0;
        padding: 8px 0;
        opacity: 0;
        display: block;
        margin: 0;
        padding: 8px 0;
        transform: scale(1.1) translateY(-24px);
        transition: opacity 0.35s ease-out, transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
        a {
          display: inline-block;
          padding: 0; } } }
    .has-sub {
      & > .sub-menu {
        position: relative; } }
    .has-sub2 {
      &> a:after {
        content: "\f0d7" !important;
        position: absolute !important;
        right: 6px !important;
        top: 9px !important; }
      & > .sub-menu {
        position: relative; }
      &.sub-open {
        &> .sub-menu {
          display: block;
          left: 0;
          top: 0; } } }

    .header_top-menu {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      padding: 16px 16px 10px;
      text-align: left;
      .with-child > a {}
      .has-sub > a {
        color: #FFF; }


      .dropdown.bootstrap-select .bttn {
        color: #FFF; } }
    &_nav {
      background: rgba(#000,.5);
      margin: 0 -12px;
      max-height: 0;
      visibility: hidden;
      overflow: hidden;
      transition: all .5s; } }
  .header_left-menu {
    padding-bottom: 0; }
  .header_right-menu {
    padding-top: 0 !important; }
  .header li .dropdown.bootstrap-select {
    display: inline-block;
    padding-right: 23px;
    position: relative;
    .bttn {
      padding: 0;
      width: auto; }
    div.dropdown-menu {
      top: calc(100% - 24px) !important; }
    //   top: auto !important
    //   bottom: calc(100% + 29px) !important
    div.dropdown-menu:after,
    div.dropdown-menu:before {
      display: none; } }


  .header .header_top-menu {
    position: absolute;
    width: 100%;
    bottom: -60px;
    padding: 0;
    border-top: 1px solid rgba(255,255,255,0.3);
    display: flex;
    > li {
      display: block;
      width: 100%;
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      padding: 15px 16px;
      margin: 0;
      &+ li {
        margin: 0;
        border-left: 1px solid rgba(255,255,255,0.3); }
      .dropdown.bootstrap-select .bttn:after {
        top: 0; } }
    .menu-lang {
      display: block; } }
  .header .header_top-menu .with-child > a {}
  .header .header_top-menu .has-sub > a {
    padding: 0;
    border: 0; }
  .header li .dropdown.bootstrap-select {
    margin-bottom: 0; }
  .header_right-menu .menu-lang {
    display: none !important; }
  .burger-menu {
    display: block; }
  body.menu-open {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000,.8);
      z-index: 3; }
    .header {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      background: transparent;
      > .container {
        background: #bc4269; }
      .menu-lang,
      .header_top-menu {
        opacity: 1 !important; }
      .header_top-menu {
        bottom: 0; }
      &_nav {
        max-height: 100vh;
        visibility: visible;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 60px; }
      &_left-menu,&_right-menu {
        &> li {
          opacity: 1;
          transform: none;
          @for $item from 1 through 10 {
            &:nth-of-type(#{$item}) {
              -webkit-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              -moz-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              -o-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              -ms-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important; } } } } } }

  .hero {
    padding-top: 180px;
    min-height: 750px;
    max-height: none;
    &_img {
      width: 60%; }
    &-about {
      padding-bottom: 220px; }
    &_detail {
      padding-top: 150px; } }


  .hero_slider {
    padding-top: 120px;
    padding-bottom: 120px; }


  .hs1 {
    padding: 88px 0;
    .title {
      margin-bottom: 48px; }
    .owl-stage-outer {
      padding: 0; } }

  .hs2 {
    &_title {
      margin-bottom: 48px; } }

  .hs4 {
    padding: 88px 0; }

  .wocas_item {
    padding-top: 48px;
    padding-bottom: 120px; }
  // .wocas .owl-dots
  //// bottom: 88px

  .es1 {
    &_img {
      margin-bottom: 48px; } }

  .es2 {
    &_item {
      &-content {
        padding-left: 30px; }
      &:nth-child(even) {
        .es2_item-content {
          padding-right: 30px; } } } }

  .pd1 {
    padding-top: 30px; }
  .pd5 {
    padding-top: 0; } }

@include responsive(1200px,800px) {
  .hero {
    &-about {
      padding-bottom: 300px; } } }

@include responsive(1200px,1000px) {
  .hs3 {
    &_content {
      padding: 33.5% 15px; }
    &_num {
      font-size: 42px; }
    &_item {
      padding: 40% 0;
      margin: 0 55px;
      background-size: contain; } }
  .hero_slider {
    min-height: auto;
 } }    // padding-bottom: 80px


@include tablet {
  .hs3 {
    .container {
      max-width: 600px; }
    &_item {
      margin: 0 0 24px; } }
  .hs2 {
    &_box {
      &:before {
        padding: 36.74% 0; } } }
  .git {
    &_wos {
      margin: 24px 0 0; } }
  .footer {
    .col-company,.order-lg-6,.order-lg-1 {
      margin-bottom: 24px; } }
  .hero {
    &_detail {
      p {
        font-size: 16px;
        line-height: 24px; }
      .col1 {
 }        // flex: 0 0 36.666667%;
      // .img2
 } }      //   margin-top: 16px
  .pd1 {
    &_text {
      padding: 30px 12px 0; }
    &_img {
      padding-top: 40px; } }
  .pd2 {
    &_img {
      margin: 0; } }
  .pd4,.pd5 {
    padding: 88px 0; }
  .pd5 {
    padding-top: 0; }
  .pd4.wocas {
    padding-bottom: 0;
    margin: 0;
    .wocas_item {
      padding-bottom: 88px; } }
  .pd3 {
    .container {
      padding: 88px 12px; } }

  .ab3 {
    &-item {
      padding: 0 16px; } }
  .footer_logo {
    img {
      max-width: 85%; } }
  // .col-menu-3,
  // .col-menu-4
  //   margin-top: 24px
  .footer .has-sub > a {
    display: block;
    min-height: 50px; } }

@include maxtablet {
  .hero {
    &_slider {
      padding: 120px 0 100px;
      min-height: auto;
      &.overlay {
        .hero_item:after {
          display: none; }
        .content {
          color: #212121;
          display: block; } }
      .hero_item {
        box-shadow: none;
        .bg_img {
          position: relative;
          top: auto;
          padding-top: 39%; }
        .content {
          max-width: 100%;
          min-height: auto;
          padding: 0 0 30px; } }
      .hero_item .content:before {
        display: none; } }
    &_detail {
      .img2 {
        height: calc(100% - 84px); }
      .img3 {
        margin-top: 20px; } } }
  .hero:before {
    bottom: 0; }
  // .hero_slider:before
  //   bottom: -18px

  .hero-about article {
    margin-left: 0; }

  .hidden-md-down {
    display: none; }

  .hs2 {
    padding-top: 0;
    padding-bottom: 232px;
    &_box.open {
      margin-bottom: 0; }
    &_col + .hs2_col {
      border: none; }
    &_nav {
      opacity: 1;
      visibility: visible; }
    &_slider {
      &:not(.owl-loaded) {
        .hs2_item {
          max-width: calc(50% - 24px);
          flex: 0 0 calc(50% - 24px); } } } }

  .wocas {
    margin-bottom: 88px;
    .title {
      margin-bottom: 32px; }
    &_item {
      flex-wrap: wrap;
      padding: 0 32px 32px;
      &_img {
        max-width: 40%;
        flex: 0 0 40%;
        padding: 0; }
      &_img .img {
        box-shadow: none; }
      &_content {
        max-width: 60%;
        flex: 0 0 60%;
        padding: 24px 0 0 16px; } }
    .owl {
      &-prev {
        left: -32px; }
      &-next {
        right: -32px; } }
    .owl-dots {
      bottom: 50px; } }

  .pd4 {
    .owl-dots {
      bottom: 90px; } }


  .es2 {
    &_item {
      &-content {
        padding-left: 0; }
      &:nth-child(even) {
        .es2_item-content {
          padding-right: 0; } } } }



  .fs1 {
    padding-bottom: 64px; }
  .fsside {
    margin-bottom: 88px; }

  .ct1 {
    margin-bottom: 64px; }
  .ctside {
    margin-bottom: 88px; }
  .pd2 {
    &_content {
      padding-right: 0; }
    &_nav {
      margin-bottom: 32px;
      a {
        padding: 0 14px;
        font-size: 16px; } }
    .owl-nav {
      top: 45%; } }

  // .pd3
  //   padding-top: 0
  //   .map
  //     position: relative
  //     height: 50vw
  //     width: 100%
  .pd3 {
    .col-lg-6 {
      max-width: 50%;
      flex: 0 0 50%; }
    &_nav a {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      min-width: auto; } }

  .ab1 {
    padding-bottom: 88px;
    .title {
      margin-bottom: 24px; } }

  .ab2 {
    padding: 88px 0;
    &-content {
      padding-left: 12px; } }

  .ab4 {
    padding: 88px 0; }

  .milestone {
    &-wrap {
      flex-wrap: wrap; }
    &-nav {
      max-width: 100%;
      flex: 0 0 100%;
      .nav {
        display: flex;
        .nav-item {
          display: block;
          width: 100%;
          padding: 32px 0 26px;
          text-align: center;
          &.open:last-child:before {
            display: none !important; }
          a {
            padding-left: 0; }
          &:after {
            top: 10px;
            left: calc(50% - 5px); }
          &:before {
            width: 100%;
            height: 1px;
            top: 5px;
            left: calc(50% - 5px); }
          &.active {
            padding: 32px 0;
            &:after {
              top: 8.5px;
              left: calc(50% - 6.5px); } } }
        .nav-item-sub {
          padding: 0 !important;
          a:before,
          a:after {
            display: none !important; }
          span {
            padding-left: 0;
            text-align: center;
            &:after {
              display: none; } } } } }
    &-content {
      max-width: 50%;
      flex: 0 0 50%; } }


  .ab2 {
    &-content {
      padding-left: 0; }
    &-chart {
      margin: 0 auto 32px;
      &-legend {
        column-count: 1;
        li {
          padding-left: 32px;
          .icon {
            width: 18px;
            height: 18px;
            top: 3px; } } } }
    &-content {} }

  .git {
    .container {
      padding-bottom: 60px; } }

  .cr3 {
    &_container {
      max-width: 720px; } } }

@include minltablet {
  .hero {
    &_detail {
      // padding-top: 216px
      .img3 {
        margin-top: 30px; }
      .container > .row {
        margin-bottom: -14vw; } } }
  .pd1 {
    &_text {
      padding: 30px 0 0 48px; } } }

@include mintablet {
  .visible-xs {
    display: none; }
  .ct {
    .sidebar {
      padding: 75px 24px; } } }



@include mobile {
  // .hero
  //   background: linear-gradient(0deg, #DDFFFF 0%, #B3D9E4 100%)
  .hero_img {
    width: 51%; }
  .hidden-xs {
    display: none; }
  .hero.hero_detail {
    padding-bottom: 60px;
    h1 {
      margin-bottom: 16px; }
    .slider {
      margin-top: 24px; } }
  h2 {
    font-size: 30px;
    line-height: 36px; }
  .title {
    margin-bottom: 32px; }
  .owl-dots {
    text-align: center; }
  [class*="mb-"] {
    margin-bottom: 24px; }
  .hero {
    height: auto;
    padding: 120px 0 175px;
    min-height: 0;
    h1 {
      font-size: 32px;
      line-height: 40px; }
    p {
      font-size: 16px;
      line-height: 24px; }
    &_slider {
      padding-bottom: 80px;
      .container {
        padding-left: 15px;
        padding-right: 15px; } } }
  .hs1 {
    padding: 48px 0;
    .col-md-3 + .col-md-3 {
      margin-top: 16px; }
    &_text,&_bg:before {
      opacity: 1; }
    &_item:before {
      padding: 60% 0; }
    .slider {
      padding: 0 24px;
      .owl {
        &-prev,&-next {
          right: -24px; }
        &-prev {
          left: -24px;
          right: auto; } } }
    &_desc {
      opacity: 1;
      max-height: 400px; }
    &_text:after {
      opacity: 1;
      right: 0; } }
  .hs2 {
    padding-top: 0;
    padding-bottom: 225px;
    margin-bottom: 48px;
    &_box {
      &:before {
        padding: 30%;
        transition: all .3s;
 }        // padding: 71.22% 0
      &.open:before {
        padding: 64.22%; } }
    &_nav {
      top: 100%;
      a {
        font-size: 16px; } }
    &_content {
      background-color: #F9F9F9;
      .slider {
        padding: 0 24px; }
      .owl {
        &-prev,&-next {
          right: -24px; }
        &-prev {
          left: -24px;
          right: auto; } }
      // .owl-dots
      //   .owl-dot
      //     width: 20px
      // .owl
      //   &-prev,&-next
      //     width: 45px
      //     height: 45px
      //     right: 0
      //     background: url("../images/ic-chevron-right-2.svg") no-repeat center
      //     background-size: 100% 100%
      //   &-prev
      //     left: 0
 } }      //     right: auto

  .hs3 {
    padding: 64px 0;
    .btn {
      margin-bottom: 12px; }
    .row {
      margin-bottom: 14px; }
    h2 {
      font-size: 30px;
      line-height: 36px;
      margin: 0 0 24px; }
    [class*="col-"]:not(:last-child) {
      margin: 0 0 24px; }
    .owl-dots {
      margin: 0; }
    &_text {
      font-size: 16px;
      line-height: 22px; }
    &_content {
      padding: 30% 15px; }
    &_num {
      font-size: 30px;
      line-height: 46px; }
    &_item {
      padding: 36% 0;
      margin: 0 55px;
      background-size: contain; } }

  .wocas {
    &_item {
      &_img,
      &_content {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0; }
      &_content {
        padding-top: 24px; } }
    .owl-prev,
    .owl-next {
      top: 340px; }
    .owl-dot {
      width: calc((100%/6) - 8px); } }
  .hs4 {
    padding: 0; }
  .git,.hs4 {
    .container {
      padding-top: 48px;
      padding-bottom: 48px; }
    [class*="col-"]:not(:last-child) {
      margin: 0 0 24px; } }
  .git {
    .container {
      padding-bottom: 0; }
    &_box1 {
      .git_content {
        position: relative; }
      &:before {
        display: none; }
 } }      // min-height: 220px

  .ctus {
    .title h2 {
      font-size: 30px;
      line-height: 36px; } }

  .es1 {
    padding-top: 80px;
    .title {
      margin-bottom: 44px; }
    &_img {
      max-width: calc(100% - 23px);
      margin-bottom: 14px; }
    .es1_img .img_text {
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 43%;
      &.it2 {
        padding-bottom: 0;
        padding-top: 42%; } }
    .es1_img .img_item.active {
      transform: scale(1.1);
      .img_text {
        font-size: 16px;
        line-height: 24px; } } }


  .es2 {
    margin-bottom: 88px;
    .title {
      margin-bottom: 32px; }
    &_item {
      margin-bottom: 64px;
      &-content {
        padding-top: 32px; } } }
  .es3 {
    .title {
      margin-bottom: 32px; }
    &_logo {
      &_item {
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
        height: 75px; } } }

  .fs {
    padding-top: 48px;
    .row {
      flex-direction: column-reverse; } }

  .fs_title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 12px; }

  .footer {
    padding: 48px 0 24px;
    &_logo {
      max-width: 300px;
      margin: auto;
      display: block; }

    .order-lg-1,.order-lg-6 {
      margin-bottom: 24px; }
    .col-logo {
      margin-bottom: 24px; }
    .col-menu h3 {
      position: relative;
      cursor: pointer;
      margin-top: 8px;
      &:after {
        content: "+";
        position: absolute;
        right: 0;
        top: 0;
        transition: all .5s; } }
    &_menu {
      border-bottom: 1px dotted #e2e2e2;
      max-height: 0;
      overflow: hidden;
      transition: all .5s; }
    .expand {
      h3 {
        &:after {
          transform: rotate(45deg); } }
      .footer_menu {
        max-height: 100vh; } }
    .menu {
      .has-sub > a {
        position: relative;
        display: block;
        margin: 10px 0;
        font-weight: normal;
        &:after {
          content: "+";
          position: absolute;
          right: 0;
          top: 0;
          transition: all .5s; } }
      .sub-menu {
        border-bottom: 1px dotted #e2e2e2;
        max-height: 0;
        overflow: hidden;
        transition: all .5s;
        padding: 0; }
      .has-sub.sub-open {
        &> a:after {
          transform: rotate(45deg); }
        .sub-menu {
          max-height: 100vh;
          padding-bottom: 10px; } } } }


  .contact-list {
    .item {
      flex-direction: column;
      &_img,
      &_content {
        max-width: 100%;
        flex: 0 0 100%; }
      &_content {
        padding: 24px 0 0; } } }
  .pd1 {
    padding: 48px 0;
    &_text {
      margin-top: 32px; }
    &_img {
      padding-top: 0; } }
  .pd2 {
    padding: 48px 0;
    &_img {
      margin: 0; }
    .owl-carousel {
      margin-bottom: 32px; }
    .owl-nav {
      top: 45%; } }
  .pd2_list {
    margin-bottom: 0; }
  .pd2_item:last-child {
    padding-bottom: 0; }
  .pd3 {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 0;
    .col-lg-6 {
      max-width: 100%;
      flex: 0 0 100%; }
    .map {
      height: 300px;
      position: relative;
      width: 100%; }
    .container {
      padding: 48px 12px; }
    &_nav {
      li {
        min-width: 0; }
      a {
        min-width: 0;
        padding: 0 10px;
        font-size: 20px; } } }

  .pd4 {
    margin: 0;
    padding: 48px 0 0;
    .wocas_item {
      padding-bottom: 48px; }
    &.wocas .owl-dots {
      bottom: 50px; } }

  .pd5 {
    padding-top: 0; }

  .ab1,
  .ab2 {
    padding: 48px 0; }

  .ab2 {
    h2 {
      font-size: 30px;
      line-height: 36px; }
    &-chart {
      width: 70%;
      .logo {
        width: 40%;
        height: 40%;
        top: 30%;
        left: 30%;
        img {
          max-width: 100%; } } } }

  .ab3 {
    padding: 96px 0;
    .btn {
      margin-bottom: 48px; }
    .row {
      margin-bottom: 0; }
    h2 {
      font-size: 30px;
      line-height: 36px; } }

  .ab4 {
    padding: 48px 0;
    [class*="col-"]:not(:last-child) {
      margin-bottom: 24px; } }

  .milestone {
    &-content,
    &-img {
      max-width: 100%;
      flex: 0 0 100%; }
    &-content {
      padding: 0 0 48px; }
    &-nav {
      padding-left: 50px;
      padding-right: 50px;
      .owl-prev,
      .owl-next {
        width: 60px;
        height: 60px;
        top: 0; }
      .owl-prev {
        left: -50px; }
      .owl-next {
        right: -50px; } } }
  .cr1 {
    padding: 88px 0; }
  .cr2 {
    &_item {
      margin-bottom: 48px; } }
  .cr3 {
    padding-bottom: 88px; }
  .accordion-careers {
    .card {
      &-header {
        &-wrap {
          flex-wrap: wrap;
          &-left,
          &-right {
            max-width: 100%;
            flex: 0 0 100%; }
          // .title,
          .subcategory,
          .location,
          .closes {
            margin-top: 6px; }
          .location {
            padding-left: 16px;
            &:before {
              left: 0; } } } } } } }

@media (max-width: 426px) {
  .hero_img {
    width: 93%; } }


@media (max-width: 340px) {
  .hs3_num {
    font-size: 24px;
    line-height: 36px; }
  .hs3_content {
    padding-top: 23%; }
  .hs3_item {
    margin: 0 30px; }

  .es1 .es1_img .img_text {
    padding-bottom: 46%; }
  .es1 .es1_img .img_text.it2 {
    padding-top: 45%; } }

