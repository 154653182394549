/* ------------------------------------------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------------------------------------------ */
.header {
  position: fixed;
  left: 0;
  width: 100%;
  top: 48px;
  z-index: 10;
  min-height: 80px;
  color: #fff;
  transition: all .3s;
  .container {
    position: relative;
    background: #bc4269;
    // background: rgba(202,5,56,0.6)
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    transition: all .3s; }
  &_logo {
    max-width: 145px;
    display: inline-block;
    margin: 12px 0; }
  &_left-menu,&_right-menu {
    position: absolute;
    width: 40%;
    top: 0;
    bottom: 0;
    padding: 26px 0 0; }
  &_left-menu {
    left: 0;
    text-align: left;
    padding-left: 2.61%;
    padding-top: 29px; }
  &_right-menu {
    right: 0;
    text-align: right;
    padding-right: 2.61%;
    padding-top: 22px; }
  .header_top-menu {
    position: absolute;
    right: 0;
    top: -35px;
    .with-child > a,
    .has-sub > a {
      color: #636362; }
    .dropdown.bootstrap-select {
      margin-top: 0; }
    .dropdown.bootstrap-select .bttn {
      color: #636362; }
    .menu-lang {
      display: none; }
    // .header_submenu
    //   right: 18px
    //   left: auto
    //   &:before
    //     left: auto
 }    //     right: 12px
  li {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    position: relative;
    &.badge {
      > a {
        background: #2b69bb;
        border-radius: 4px;
        padding: 4px 12px !important;
        border: 0 !important; } }
    &.hide {
      display: none !important; }
    &+ li {
      margin-left: 7%; }
    select {
      opacity: 0; }
    .dropdown.bootstrap-select {
      margin-bottom: 10px;
      margin-top: 8px;
      .bttn {
        border: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        text-transform: uppercase;
        &:after {
          content: "\f0d7";
          top: 4px;
          line-height: 24px;
          font-size: 12px; } }
      div.dropdown-menu {
        background: #293037;
        border-radius: 4px;
        border: 0;
        top: 13px !important;
        overflow: visible !important;
        &:before {
          content: "";
          position: absolute;
          @include triangle('up',12px,6px,#293037);
          top: -6px;
          left: 12px; }
        li {
          display: block;
          margin: 0;
          a {
            border: 0;
            color: #fff;
            font-size: 16px;
            line-height: 26px;
            font-weight: bold;
            text-transform: uppercase;
            display: block; }
          &.selected a {
            background: transparent; } } } }

    &.with-child,
    &.has-sub {
      &> a {
        pointer-events: none;
        max-height: 44px;
        &:after {
          content: "\f0d7";
          font-family: fontAwesome;
          width: 20px;
          text-align: center;
          display: inline-block;
          font-size: 12px;
          position: relative;
          top: -2px; } }
      &.sub-open {
        z-index: 2;
        .header_submenu,
        &> .sub-menu {
          display: block; } }
      &:hover {
        @include responsive(0,1200px) {
          &> .header_submenu,
          &> .sub-menu {
            display: block; } } } }

    &.header_enquiry {
      span {
        font-size: 11px;
        line-height: 14px;
        font-weight: bold;
        text-transform: uppercase;
        display: block; } }
    a {
      display: block;
      color: #fff;
      font-size: 16px;
      line-height: 26px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 8px 0;
      border-bottom: 2px solid transparent;
      &:hover {
        opacity: 0.5; } }
    &.active,
    &.current-menu-item {
      > a {
        border-color: #fff; } } }
  &_submenu,
  .sub-menu {
    position: absolute;
    min-width: 200px;
    text-align: left;
    top: 90%;
    display: none;
    margin-top: 8px;
    background: #293037;
    border-radius: 4px;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 8px;
      top: -8px;
      position: absolute;
      left: 0; }
    &:before {
      content: "";
      position: absolute;
      @include triangle('up',12px,6px,#293037);
      top: -6px;
      left: 12px; }
    &.short {
      min-width: 0; }
    li {
      padding: 10px 12px;
      margin: 0;
      display: block;
      + li {
        margin-left: 0; } }
    a {
      padding: 0;
      border: none; }
    .has-sub2 {
      &> a:after {
        content: "\f0da";
        font-family: fontAwesome;
        width: 20px;
        text-align: center;
        display: inline-block;
        font-size: 12px;
        position: relative;
        top: -2px;
        float: right; }
      &:hover,
      &.sub-open {
        @include responsive(0,1200px) {
          &> .sub-menu {
            display: block; } } } }
    .sub-menu {
      display: none;
      top: 0;
      left: 100%;
      margin: 0 0 0 8px;
      &:after {
        width: 8px;
        height: 100%;
        left: -8px;
        top: 0; }
      &:before {
        display: none; } } }

  &.sticky {
    top: 0;
    background: #bc4269;
    // background: rgba(202,5,56,0.8)
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    .header_logo {
      max-width: 100px; }
    .header_left-menu {
      padding-top: 18px; }
    .header_right-menu {
      padding-top: 12px; }
    .container {
      // max-width: 100%
      background: transparent;
      border-radius: 0;
      box-shadow: none; }
    // .header_top-menu
    //   position: absolute
    //   top: 100%
    //   right: 35px
    //   padding: 5px 7px 2px 13px
    //   background: #FFF
    //   border-radius: 0 0 3px 3px
    //   box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15)
    //   li a
    //     padding: 0 !important
    //   .header_submenu
    //     right: -7px
    //     left: auto
    //     &:before
    //       left: auto
 } }    //       right: 12px



.burger-menu {
  position: absolute;
  right: 12px;
  top: 22px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  z-index: 2;
  border-radius: 2px;
  cursor: pointer;
  display: none;
  span {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 3px;
    background: #fff;
    transition: transform .5s, top .5s .5s;
    &:nth-child(2) {
      width: 13px;
      top: 12px; }
    &:nth-child(3) {
      top: 19px;
      width: 19px; } } }




body.menu-open {
  overflow: hidden;
  header {
    .header_logo {
      right: 154px;
      svg {
        path {
          fill: #fff; } } } }
  .header_nav {
    opacity: 1;
    visibility: visible; }
  nav {
    opacity: 1;
    transform: none; }
  .burger-menu {
    span {
      transition: top .5s, transform .5s .5s;
      &:nth-child(1) {
        top: 15px;
        transform: rotate(45deg); }
      &:nth-child(3) {
        width: 24px;
        top: 15px;
        transform: rotate(-45deg); }
      &:nth-child(2) {
        opacity: 0; } } } }



