/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */
.footer {
  padding: 72px 0 44px;
  background: #595959;
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  h3 {}
  .has-sub > a {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin: 0 0 8px;
    pointer-events: none;
    display: block; }
  a {
    color: #fff;
    &:hover {
      opacity: 0.5; } }
  &_social {
    a {
      display: block; }
    .fa {
      font-size: 18px;
      line-height: 18px;
      margin-right: 16px;
      width: 18px; } }
  .footer_copy {
    p span {
      display: inline-block;
      margin: 0 6px; } }
  .dropdown.bootstrap-select {
    display: inline-block;
    color: #fff;
    .bttn {
      border: none;
      line-height: 24px;
      &:after {
        line-height: 24px;
        top: 0; } } }

  .row {} }


