/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */


.hiden {
  display: none; }

input,textarea {
  padding: 0;
  border-radius: 0; }

//body
//background: #eff2f7
//opacity: 0
//transition: all .5s
//&.ready
//  opacity: 1



h2 {
  font-size: 42px;	line-height: 51px;
  font-weight: bold;
  margin: 0 0 24px;
  color: $red; }

.btn {
  text-align: center;
  display: inline-block;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  vertical-align: top;
  border-radius: 4px;
  border: none;
  color: #fff;
  background: $red;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  &:hover {
    background: darken($red,10%); }
  &:focus {
    outline: none; }
  &.big {
    line-height: 55px; }
  &.disabled {
    background: #DDDDDD;
    pointer-events: none; }
  &.block {
    width: 100%; }
  &-gray {
    background: #D8D8D8;
    color: #212121;
    &:hover {
      background: darken(#D8D8D8,10%); } } }




.title {
  margin: 0 auto 44px;
  text-align: center;
  p {
    max-width: 856px;
    font-size: 18px;
    line-height: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto; } }

p,article {
  &:not(:last-child) {
    margin-bottom: 24px; } }

.owl {
  &-prev,&-next {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(50% - 40px);
    right: -80px;
    background: url("../images/ic-chevron-right.svg") no-repeat center;
    background-size: 100% 100%;
    cursor: pointer; }
  &-prev {
    left: -80px;
    right: 0;
    transform: scaleX(-1); }

  &-dots {
    margin: 12px 0 0;
    text-align: right; }

  &-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $red;
    margin: 0 4px;
    &:not(.active) {
      background: #C6C6C6; } } }
.slider-custom-dot {
  .owl-dots {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    line-height: 4px; }
  .owl-dot {
    height: 4px;  width: 64px;  background: #D8D8D8;
    border-radius: 0;
    &.active {
      background: #B4B4B4; } } }

.block {
  display: block; }
a {
  color: $red;
  transition: all .5s;
  &.full-link {
    @include absfull;
    z-index: 2; }
  &.line {
    text-decoration: underline; }
  &.with-arrow {
    padding-left: 40px;
    position: relative;
    display: inline-block;
    font-weight: bold;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: url("../images/red-long-arrow.svg") no-repeat center;
      background-size: 100% auto;
      width: 29px;
      transition: all .5s; }
    &.white {
        color: #fff;
        &:before {
          background-image: url("../images/white-long-arrow.svg"); } }
    &:hover {
      text-decoration: underline;
      &:before {
        left: 8px; } } } }

.fright {
  float: right; }
.text-right {
  text-align: right; }

.slider:not(.owl-loaded) {
  &> *:not(:first-child) {
    display: none; } }



*:focus {
  outline: none; }

.text-center {
  text-align: center; }

body.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 101%;
  height: 100vh;
  overflow: auto;
  display: none;
  transition: all .5s;
  &:not(.show) {
    opacity: 0;
    .modal-dialog {
      transform: translate(0,-30px); } }
  .modal-dialog {
    max-width: 540px;
    padding: 32px 24px;
    background: #fff;
    margin: 10vh auto;
    transition: all .5s ease-out;
    position: relative;
    border: 1px solid #E2E2E2;
    h3 {
      text-align: center; } }
  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    display: block;
    opacity: .3;
    transition: all .3s;
    cursor: pointer;
    &:hover {
      opacity: .7; } }
  .form-group {
    text-align: left; }
  a.back {
    margin: 0 0 40px; }
  h3 {
    color: $red;
    font-weight: bold;
    margin: 0 0 12px;
    font-size: 26px;	line-height: 31px; }
  p {
    margin: 0 0 24px !important; }
  &.purple {
    .modal-dialog {
      background: $purple;
      color: #fff; }
    a.back {
      margin: 0 0 28px; } }
  .range_slider {
    margin-bottom: 30px; }
  .ctrip_range {
    margin-top: 0; }

  &-bottom {
    top: auto;
    bottom: 0;
    background: rgba(#fff,.8);
    &:not(.show) {
      .modal-dialog {
        transform: translate(0,50%); } }
    h2 {
      display: inline-block;
      margin: 0 0 30px;
      font-size: 22px;
      letter-spacing: -0.07px;
      line-height: 27px; }

    .modal-dialog {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      min-height: 0;
      box-shadow: 0 6px 14px 5px rgba(0, 0, 0, 0.5); } } }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7);
  z-index: 10; }
.slider:not(.owl-carousel) {
  &> * {
    &:not(:first-child) {
      display: none; } } }

.tab-pane {
  ul {
    list-style: disc;
    padding-left: 25px; }

  &:not(.active) {
    display: none; } }


.list-checkmark {
  list-style: none;
  padding: 0;
  &> li {
    padding-left: 30px;
    margin-bottom: 8px;
    position: relative;
    &:last-child {
      margin-bottom: 0; }
    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background: url("../images/ic-checkmark.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      left: 0;
      top: calc(50% - 8px); } } }


.alert {
  padding: 10px;
  border-radius: 2px;
  background: #f9f9f9;
  border: 1px solid #e2e2e2;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  &-center {
    text-align: center; }
  &-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  &-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; } }

.fade {
  transition: opacity 0.15s linear; }

.fade:not(.in):not(.show) {
  display: none; }


.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }


.accordion {
  &_title {
    position: relative;
    // font-size: 15px
    // line-height: 24px
    font-weight: bold;
    margin: 0;
    cursor: pointer;
    padding-right: 26px;
    &:after {
      content: "";
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background-image: url('../images/ic-chevron-right.svg');
      background-size: 200%;
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(90deg);
      transition: transform .3s ease; }
    &.collapsed:after {
      transform: rotate(0); } }
  .card {
    border-bottom: 1px solid #E2E2E2;
    &-header {
      padding: 12px 0; }
    &-body {
      padding: 4px 0 32px;
      ul {
        li {
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0; } } } } } }


.sidebar {
  background: #EFF3F7;
  border-radius: 6px;
  padding: 48px 24px; }

.side-menu {
  &-toggle {
    .item {
      cursor: pointer; }
    .item:not(.active):hover {
      opacity: .5; } }
  .item {
    display: block;
    padding: 24px 0;
    border-bottom: 1px solid #CDD2D7;
    opacity: 1;
    transition: all .3s ease;
    color: #212121;
    &:last-child {
      border-bottom-color: transparent; }
    &_title {
      display: block;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: {}
      margin-bottom: 8px;
      font-weight: bold; }
    &.active {
      .item {
        &_title {
          color: $red; } } } } }

.dropdown.bootstrap-select.nav-select {
  .bttn {
    background: #fff; } }

// mapbox
// .wp-mapbox-gl-js-map.mapboxgl-map
//   height: 100% !important
// .mapboxgl-popup-close-button
//   display: none
// .mapboxgl-popup-content
//   color: #212121
//   img
//     margin-bottom: 8px
// .mapboxgl-popup
//   display: none !important



.ums_map_opts,
.umsMapDetailsContainer,
.ums_MapPreview {
  position: absolute;
  height: 100% !important;
  width: 100%; }
.leaflet-popup-close-button {
  display: none !important; }
.umsMarkerTitle {
  display: none !important; }
.infobox-info img, .umsMarkerDesc img {
  min-width: 160px; }
.leaflet-popup-content p {
  margin: 0 0 8px !important; }
.leaflet-popup-content-wrapper {
  border-radius: 0 !important; }
.leaflet-marker-pane {
  img {
    width: 30px !important;
    left: -15px !important; } }
.umsMarkerDesc {
  text-align: center !important; }


@media (max-width: 720px) {
  .ums_map_opts .umsMapDetailsContainer,
  .ums_map_opts .umsMapMarkerFilters {
    height: 100% !important; } }

.form-search {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  &-group {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center; }
  input {
    height: 42px;
    width: 100%;
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 10px;
    font-size: 15px;
    line-height: 24px;
    display: block;
    margin-right: 8px; }
  button {
    background-color: #636362;
    border: 1px solid #636362;
    border-radius: 4px;
    padding: 0 25px;
    font-size: 15px;
    line-height: 40px;
    color: #FFF; } }


.disabled {
  pointer-events: none;
 }  // opacity: .5
